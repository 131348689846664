import React from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

const initialize = () => {
    const SENTRY_DSN = window.joshuGlobals.SENTRY_DSN;
    const SENTRY_ENVIRONMENT = window.joshuGlobals.SENTRY_ENVIRONMENT;
    const SENTRY_RELEASE = window.joshuGlobals.SENTRY_RELEASE;

    if (!SENTRY_DSN) {
        return;
    }

    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: SENTRY_RELEASE,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],

        tracesSampleRate: 1.0,
    });
};

export default initialize;
